/* eslint-disable */
import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    <strong>
                        Un any més, oferim un entrenament intensiu dissenyat específicament per
                        preparar les proves físiques d'accés a la Policia Local de Castelldefels
                        2025.
                    </strong>
                </p>

                <p>
                    🕒 <strong>Sessió intensiva de 2 hores</strong>
                </p>

                <p>
                    Amb la participació de{" "}
                    <strong>2 docents de referència en preparació física policial</strong>.
                </p>

                <p>
                    📍 <strong>Lloc:</strong> CEM Besòs, Sant Adrià de Besòs
                    <br />
                    👥 <strong>Grups reduïts</strong> per garantir una atenció personalitzada
                    <br />
                    📏 <strong>Toma de marques inclosa</strong> per avaluar el teu rendiment real
                </p>

                <p>
                    🗓 <strong>Data i hora:</strong>
                    <br />
                    Dissabte 19 d’abril, de 13.00 a 15.00 h
                </p>

                <p>
                    🏋️‍♀️ <strong>Proves a realitzar:</strong>
                </p>
                <ul>
                    <li>Cursa Llançadora (Course Navette)</li>
                    <li>Llançament de pilota medicinal</li>
                    <li>Salt horitzontal</li>
                    <li>Adaptació al medi aquàtic</li>
                </ul>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate item={item} title="">
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
